import { Auth0Provider } from '@auth0/auth0-react';
import { event, pageview } from '@marine/lib';
import { Theme } from '@marine/theme';
import '@marine/theme/antd/antd.min.css';
import { GraphQLClient } from 'graphql-request';
import { getMessages } from 'lang';
import { eventNames } from 'mdc-constants';
import App, { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import 'keen-slider/keen-slider.min.css';
import { Hydrate, QueryClientProvider, dehydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Inter } from 'next/font/google';
import {
  ConfigProvider,
  GraphQLClientProvider,
  GraphQLClientState,
  UserSetupStatusProvider,
  useConfig,
} from 'providers';
import { useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { getQueryClient, queryClient } from 'services/queryClient';
import '../styles/globals.scss';
import { withPasswordProtect } from '@marine/next-password-protect';
import { useReferralToken } from '@marine/utils';
import { browserEnv, getAppConfiguration } from '@utils/browserEnv';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const inter = Inter({ subsets: ['latin'] });

const Main = ({ Component, pageProps }: AppProps<{ dehydratedState: unknown }>) => {
  const router = useRouter();
  const { locale, defaultLocale } = router;
  const { mdcGraphQlUrl, googleAnalyticId, auth0Domain, auth0ClientId, auth0Audience, theboatAppUrl } = useConfig();

  const { referralToken } = useReferralToken();

  const messages = getMessages(locale);

  useEffect(() => {
    if (googleAnalyticId) {
      const handleRouteChange = (url) => {
        pageview(url, googleAnalyticId);
      };

      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }

    return () => {};
  }, [router.events, googleAnalyticId]);

  const onRedirectCallback = (appState) => {
    event({
      action: eventNames.userLoggedIn,
      category: 'authentication',
      label: eventNames.userLoggedInLabel,
      value: appState?.targetUrl,
    });

    if (referralToken) {
      router.replace(`${theboatAppUrl}?via=${referralToken}`);
    } else {
      router.replace(`${theboatAppUrl}`);
    }
  };

  const handleGlobalError = (_error) => {};

  const queryClientTest = getQueryClient({ onError: handleGlobalError });

  const [reactQueryClient] = useState(queryClientTest);

  const appGraphQLClient: GraphQLClientState = {
    graphQLClient: new GraphQLClient(mdcGraphQlUrl!!),
  };

  const redirectUri = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window.location.origin;
    }

    return undefined;
  }, []);

  return (
    <Theme fonts={inter.style.fontFamily} className={inter.className}>
      <Auth0Provider
        useRefreshTokens
        domain={auth0Domain!!}
        clientId={auth0ClientId!!}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          redirect_uri: redirectUri,
          audience: auth0Audience!!,
        }}
      >
        <UserSetupStatusProvider>
          <GraphQLClientProvider defaultState={appGraphQLClient}>
            <QueryClientProvider client={reactQueryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                <IntlProvider defaultLocale={defaultLocale} locale={locale || 'en'} messages={messages}>
                  {!!googleAnalyticId && (
                    <>
                      <Script
                        strategy="afterInteractive"
                        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticId}`}
                      />
                      <Script
                        id="gtag-init"
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                          __html: `
                          window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());
                          gtag('config', '${googleAnalyticId}', {
                            page_path: window.location.pathname,
                          });
                        `,
                        }}
                      />
                    </>
                  )}
                  <Component {...pageProps} />
                </IntlProvider>
              </Hydrate>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </GraphQLClientProvider>
        </UserSetupStatusProvider>
      </Auth0Provider>
    </Theme>
  );
};

const AppWithConfigs = (props) => (
  <ConfigProvider values={getAppConfiguration()}>
    <Main {...props} />
  </ConfigProvider>
);

AppWithConfigs.getInitialProps = async (appContext) => {
  // Cache CollectionInfo before rendering pages

  await App.getInitialProps(appContext);

  return {
    pageProps: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

const ProtectedApp = withPasswordProtect(AppWithConfigs, {
  loginApiUrl: '/api/login',
  checkApiUrl: '/api/passwordCheck',
});

export default browserEnv.NEXT_PUBLIC_PASSWORD_PROTECT ? ProtectedApp : AppWithConfigs;
